import { Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage),
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage),
  },
  {
    path: 'registration',
    loadComponent: () => import('./pages/registration/registration.page').then(m => m.RegistrationPage),
  },
  {
    path: 'profile',
    loadComponent: () => import('./pages/profile/profile.page').then(m => m.ProfilePage),
    canActivate: [authGuard],
  },
  {
    path: 'join',
    loadComponent: () => import('./pages/join/join.page').then(m => m.JoinPage),
  },
  {
    path: ':id',
    loadComponent: () => import('./pages/live/live.page').then(m => m.LivePage),
    // canActivate: [authGuard]
  },
  {
    path: 'scene/:id',
    loadComponent: () => import('./pages/scene/scene.page').then(m => m.ScenePage),
    // canActivate: [authGuard]
  },
  {
    path: 'scene/:id/learning-statistics',
    loadComponent: () => import('./pages/scene/learning-statistics/learning-statistics.page').then(m => m.LearningStatisticsPage)
  },
  {
    path: 'scene/:id/field/:id',
    loadComponent: () => import('./pages/scene/field/field.page').then(m => m.FieldPage),
  },
  {
    path: 'scene/:id/selfassignment/:id',
    loadComponent: () => import('./pages/scene/self-assignment/self-assignment.page').then(m => m.SelfAssignmentPage),
  },
  {
    path: 'email-confirmation/:token',
    loadComponent: () => import('./pages/email-confirmation/email-confirmation.page').then(m => m.EmailConfirmationPage),
  },
  {
    path: 'neptun-modal',
    loadComponent: () => import('./shared/neptun-modal/neptun-modal.page').then(m => m.NeptunModalPage)
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'prefix',
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'prefix',
  },

];
