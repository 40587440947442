import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Subscription, firstValueFrom } from 'rxjs';
import { TranslatePipe } from '../translate/pipes/translate.pipe';
import { FBaseService, collection } from './firebase/f-base.service';
import { ModalController, ToastController } from '@ionic/angular';
import { LoggerService } from './logger.service';
import { NavigationStart, Router } from '@angular/router';
import { User } from '../../../../../models/User';
import { NeptunModalPage } from '../neptun-modal/neptun-modal.page';
import { JOINCODE } from '../regexes/regex';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  firebaseUser: User;
  neptunToast: HTMLIonToastElement;
  googleformToast: HTMLIonToastElement;
  navigationSub?: Subscription;
  neptunModal: HTMLIonModalElement;

  constructor(
    private afAuth: AngularFireAuth,
    private afStore: AngularFirestore,
    private afFun: AngularFireFunctions,
    private translate: TranslatePipe,
    private toastController: ToastController,
    private firebaseService: FBaseService,
    private logger: LoggerService,
    private modalController: ModalController,
    private router: Router
  ) { }

  async login(email: string, password: string) {
    return await this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async logout() {
    await this.afAuth.signOut();
  }

  getAll() {
    return this.afStore.collection(collection.users).valueChanges();
  }

  async add(data: any, password: string): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'CREATE',
        userData: data,
        password: password,
      })
    );
  }

  async update(data: any): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'UPDATE',
        userData: data,
      })
    );
  }

  async delete(userId: string): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'DELETE',
        userId: userId,
      })
    );
  }

  async addNeptunCode(userId: string, neptunCode: string | null, previousNeptunCode?: string | null, hashNeeded: boolean = false): Promise<any> {
    if (hashNeeded && neptunCode !== null && neptunCode !== '') {
      neptunCode = await this.logger.hashString(neptunCode);
    }
    if (previousNeptunCode !== neptunCode) {
      const path = window.location.pathname.split('/');
      let setOnLaPage = false;
      if (path[path.length - 1].match(JOINCODE)) {
        setOnLaPage = true;
      }
      this.logger.addLogItem('NEPTUN_CODE_SET',
        {
          setOnLiveassignmentPage: setOnLaPage,
          type: neptunCode === '' || neptunCode === null ? 'DELETE' : 'ADD',
          neptunCode: neptunCode,
          email: this.firebaseUser?.email,
          userId: this.firebaseUser?.id,
          oldNeptunCode: previousNeptunCode
        });
      return await firstValueFrom(
        this.afFun.httpsCallable('userFunctions')({
          action: 'ADD_NEPTUN_CODE',
          userId: userId,
          neptunCode: neptunCode,
        })
      );
    } else {
      return { info: 'HASHED_NEPTUN_IS_SAME' }
    }
  }

  async sendEmailVerification(userId: string): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'SEND_EMAIL_VERIFICATION',
        userId: userId,
      })
    );
  }

  async confirmEmailVerification(token: string): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'CONFIRM_EMAIL_VERIFICATION',
        token: token,
      })
    );
  }

  async checkEmailConfirmed(email: string): Promise<any> {
    return await firstValueFrom(
      this.afFun.httpsCallable('userFunctions')({
        action: 'CHECK_EMAIL_CONFIRMED',
        email: email,
      })
    );
  }

  async checkIfNeptunNeeded(email: string) { // Ha benne van a kollekcióban az email, akkor nem kell neptun kód
    const res = (await firstValueFrom(
      this.firebaseService.getDataByField(collection.allowedEmailsWithoutNeptun, [
        { fieldPath: 'email', opStr: '==', value: email },
      ], undefined, undefined, null, true)
    )) as any[];
    if (res.length > 0 && res[0].email === email) {
      return false;
    } else {
      return true;
    }
  }


  async checkUserNeptunCode(user: any, manualCheck: boolean = false) {
    const userId = user.uid;
    const userEmail = user.email;
    const path = window.location.pathname;
    if (userId && (path === '/' || path === '/login' || manualCheck)) {
      const neptunNeeded = await this.checkIfNeptunNeeded(userEmail);
      if (neptunNeeded) {
        this.firebaseUser = (await firstValueFrom(this.firebaseService.getById(collection.users, userId))) as User;
        if (this.firebaseUser) {
          const userNeptunCode = this.firebaseUser.neptunCode || null;
          if (!userNeptunCode) {
            if (manualCheck) {
              const res = await this.showNeptunModal(path);
              return { info: res?.role };
            } else {
              this.showNeptunCodeMissingToast();
              return { info: 'NEPTUN_CODE_MISSING' };
            }
          } else {
            return { info: 'NEPTUN_CODE_EXIST' };
          }
        } else {
          return null;
        }
      } else {
        return { info: 'NEPTUN_NOT_NEEDED' };
      }
    } else {
      return null;
    }
  }

  async showNeptunCodeMissingToast() {
    this.navigationSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.neptunToast) {
          this.neptunToast.dismiss();
        }
      }
    });
    if (this.neptunToast) {
      this.neptunToast.dismiss();
    }
    this.neptunToast = await this.toastController.create({
      message: this.translate.transform('MISSING_NEPTUN_CODE_TOAST'),
      color: 'primary',
      mode: 'ios',
      position: 'bottom',
      layout: 'stacked',
      cssClass: 'custom-toast',
      buttons: [
        {
          text: this.translate.transform('ADD_NEPTUN_CODE'),
          handler: async () => {
            await this.showNeptunModal(window.location.pathname, this.translate.transform('ADD_NEPTUN_CODE'), 'small');
          },
        },
        {
          icon: 'close',
          role: 'cancel',
        },
      ],
    });
    await this.neptunToast.present();
  }


  async addNeptunCodeIfValid(neptunCode: any, path: string) {
    const skipNeptunExportsCheck = true; //TODO majd ki kell venni ha már nem lesz rá szükség
    try {
      const neptunExports = await firstValueFrom(this.firebaseService.getDataByField(collection.neptunExports, [{ fieldPath: 'neptunCode', opStr: '==', value: neptunCode }], undefined, undefined, undefined, true));
      if (neptunExports.length > 0 || skipNeptunExportsCheck) {
        let result = await this.addNeptunCode(this.firebaseUser.id, neptunCode, null);
        if (result?.data?.status === 'success') {
          const scenesUpdated = result?.data?.scenesUpdated || null;
          if (path === '/') {
            if (scenesUpdated) {
              this.logger.showToast(scenesUpdated + ' ' + this.translate.transform('STUDENT_ADDED_TO_SUBJECTS'), 1500, 'bottom');
            } else {
              this.logger.showToast(this.translate.transform('SAVE_SUCCESSFUL'), 1500, 'bottom');
            }
          }

          return { neptunCode, info: 'ADDED_SUCCESSFULLY' };
        } else {
          return { neptunCode, info: 'ERROR', error: result };
        }
      } else {
        let invalidHashedCode = await this.logger.hashString(neptunCode);
        this.logger.showToast(this.translate.transform('NEPTUN_CODE_NOT_FOUND'), 3500, 'bottom');
        return { neptunCode: invalidHashedCode, info: 'NEPTUN_CODE_NOT_FOUND' };
      }
    } catch (error) {
      this.logger.handleError(error, true);
      return { neptunCode: null, info: 'ERROR', error: error };
    }
  }

  async showNeptunModal(path?: string, labelText?: string, size: 'small' | 'medium' = 'medium') {
    this.neptunToast?.dismiss();
    this.neptunModal = await this.modalController.create({
      component: NeptunModalPage,
      cssClass: 'neptun-modal ' + size,
      backdropDismiss: false,
      componentProps: {
        path: path ? path : null,
        labelText: labelText ? labelText : null,
        size: size,

      },
    });
    await this.neptunModal.present();
    const { data, role } = await this.neptunModal.onDidDismiss();
    return { data, role };
  }

  async checkUserFeedbackdata(userId: any, force: boolean = false) {
    const path = window.location.pathname;
    if (userId && (force || path === '/' || path === '/login' || path === '/profile' || (path.includes('/scene') && !path.includes('selfassignment')))) {
      this.firebaseUser = (await firstValueFrom(this.firebaseService.getById(collection.users, userId))) as User;
      if (this.firebaseUser) {
        const feedbackData = this.firebaseUser.feedbackData || null;
        if (feedbackData && feedbackData.criteriaSatisfied && !feedbackData.given) {
          await this.showGoogleformToast();
        }
      }
    }
  }

  async showGoogleformToast() {
    this.navigationSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.googleformToast) {
          this.googleformToast.dismiss();
        }
      }
    });
    if (this.googleformToast) {
      this.googleformToast.dismiss();
    }
    this.googleformToast = await this.toastController.create({
      message: this.translate.transform('FEEDBACK_CRITERIA_SATISFIED'),
      color: 'primary',
      mode: 'ios',
      position: 'bottom',
      layout: 'stacked',
      cssClass: 'custom-toast',
      buttons: [
        {
          text: this.translate.transform('FILL_GOOGLE_FORM'),
          role: 'fill',
        },
        {
          icon: 'close',
          role: 'cancel',
        },
      ],
    });
    await this.googleformToast.present();
    const { role } = await this.googleformToast.onDidDismiss();
    if (role === 'fill') {
      await this.navigateToGoogleform();
    }
  }

  async navigateToGoogleform() {
    try {
      const neptunCode = this.firebaseUser.neptunCode || '';
      const usersGivenFeedback = await firstValueFrom(this.firebaseService.getDataByField(collection.users, [{ fieldPath: 'feedbackData.given', opStr: '==', value: true }], undefined, undefined, undefined, true));
      let googleFormUrl = '';
      if (usersGivenFeedback.length % 2 === 0) {
        googleFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLScTx1iLchVG5oMT42uS3vS6IVM4coeeNYadE7JR5sYRafs_pw/viewform?usp=pp_url&entry.188606175=' + neptunCode;
      } else {
        googleFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSdQ8de_p5aae_QKztZffX-UuzFYwRColszf4MYxIPhXezWGkg/viewform?usp=pp_url&entry.122802968=' + neptunCode;
      }
      this.firebaseUser.feedbackData.given = true;
      this.firebaseUser.feedbackData.questionnaireUrl = googleFormUrl;
      await this.firebaseService.saveOrUpdateData(collection.users, this.firebaseUser);
      window.open(googleFormUrl, '_blank');
    } catch (error) {
      this.logger.handleError(error);
    }
  }
}
