import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { QueryParam } from '../../models/query-parameter.model';
import { Observable, catchError, map, of } from 'rxjs';
import { OrderBy } from '../../models/order-by.model';
import { LoggerService } from '../logger.service';

export const collection = {
  users: 'Users',
  quizzes: 'Quizzes',
  quizpackages: 'QuizPackages',
  liveassignments: 'LiveAssignments',
  liveassignmentparticipants: 'LiveAssignmentParticipants',
  quizresponses: 'QuizResponses',
  groups: 'Groups',
  subjects: 'Subjects',
  lessons: 'Lessons',
  studentLessonStatistics: 'StudentLessonStatistics',
  generationProcesses: 'GenerationProcesses',
  fields: 'Fields',
  topics: 'Topics',
  subtopics: 'Subtopics',
  scenes: 'Scenes',
  studentSceneStatistics: 'StudentSceneStatistics',
  selfAssignments: 'SelfAssignments',
  neptunExports: 'NeptunExports',
  allowedEmailsWithoutNeptun: 'AllowedEmailsWithoutNeptun',
  externalLinks: 'ExternalLinks'
};
@Injectable({
  providedIn: 'root',
})
export class FBaseService {
  constructor(
    private afs: AngularFirestore,
    private logger: LoggerService
  ) { }

  getDataByField(collection: string, where: { fieldPath: string; value: any; opStr: any }[] = [], orderBy?: OrderBy, limit?: number, startAfter?: any, noOrder: boolean = false): Observable<any[]> {
    return this.afs
      .collection(collection, ref => {
        let query: CollectionReference | Query = ref;
        for (const w of where) {
          if (w?.value) {
            query = query.where(w.fieldPath, w.opStr, w.value);
          }
        }
        if (orderBy && orderBy.active && orderBy.direction) {
          query = query.orderBy(orderBy.active, orderBy.direction);
        } else {
          if (!noOrder) {
            query = query.orderBy('metadata.createdAt', 'desc');
          }
        }
        if (startAfter) {
          query = query.startAfter(startAfter);
        }
        if (limit) {
          query = query.limit(limit);
        }
        return query;
      })
      .valueChanges();
  }

  get(
    collectionName: string,
    joinCode?: string,
    status?: string | null,
    except?: 'except' | 'notExcept' | '' | null,
    userId?: string | null,
    orderBy?: { value: string; direction: 'asc' | 'desc' },
    startAt?: any,
    limit?: any
    /* parent?: string,
    parentPath = 'parentId',
    opStr = '==' */
  ) {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        /* if (parent) {
      query = query.where(parentPath, opStr as any, parent);
    } */
        if (limit) {
          query = query.limit(limit);
        }
        if (orderBy) {
          query = query.orderBy(orderBy.value, orderBy.direction);
        }
        if (startAt) {
          query = query.startAt(startAt[orderBy?.value ? orderBy.value : 'id']);
        }
        if (joinCode) {
          query = query.where('joinCode', '==', joinCode);
        }
        if (status) {
          query = query.where('status', except === 'except' ? '!=' : '==', status);
        }
        if (userId) {
          query = query.where('author.reference', '==', userId);
        }
        return query;
      })
      .valueChanges();
  }

  getByField(collectionName: string, limit: number | null = 24, startAt?: any, parentId?: string, parentPath?: string, opStr = '==', orderBy?: { active: string; direction: 'asc' | 'desc' }) {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (parentId && parentPath) {
          query = query.where(parentPath, opStr as any, parentId);
        }
        if (limit) {
          query = query.limit(limit);
        }
        if (orderBy?.active && orderBy?.direction) {
          query = query.orderBy(orderBy.active, orderBy.direction);
        } else {
          query = query.orderBy('id');
        }
        if (startAt) {
          query = query.startAfter(startAt);
        }
        return query;
      })
      .valueChanges();
  }

  getLessonsBySceneId(sceneId: string): Observable<any> {
    return this.afs
      .collection(collection.lessons, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('sceneId', '==', sceneId);
        return query;
      })
      .valueChanges();
  }

  getLessonStatsBySceneId(sceneId: string, studentId: string): Observable<any> {
    return this.afs
      .collection(collection.studentLessonStatistics, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('sceneId', '==', sceneId);
        query = query.where('studentId', '==', studentId);
        return query;
      })
      .valueChanges();
  }


  updateLessonStatFields(id: string, currentBlock?: {
    completedCount?: number, viewedCount?: number,
    quizzes?: Array<string>, quizResponses?: Array<string>
  },
    completedQuizzes?: Array<{ id: string, correctlyAnswered: boolean }>,
    completionPercentage?: number,
    successPercentage?: number) {
    const updateData: any = {
      'metadata.lastUpdatedAt': new Date().toISOString()
    };
    if (currentBlock?.completedCount !== undefined) {
      updateData['currentBlock.completedCount'] = currentBlock.completedCount;
    }

    if (currentBlock?.viewedCount !== undefined) {
      updateData['currentBlock.viewedCount'] = currentBlock.viewedCount;
    }

    if (currentBlock?.quizzes !== undefined) {
      updateData['currentBlock.quizzes'] = currentBlock.quizzes;
    }

    if (currentBlock?.quizResponses !== undefined) {
      updateData['currentBlock.quizResponses'] = currentBlock.quizResponses;
    }

    if (completedQuizzes !== undefined) {
      updateData['completedQuizzes'] = completedQuizzes;
    }
    if (successPercentage !== undefined) {
      updateData['successPercentage'] = successPercentage;
    }
    if (completionPercentage !== undefined) {
      updateData['completionPercentage'] = completionPercentage;
    }
    return this.afs
      .collection('StudentLessonStatistics')
      .doc(id)
      .update(updateData);
  }


  updateLessonStatCompletedQuizzes(id: string, completedQuizzes: { id: string, correctlyAnswered: boolean }) {
    return this.afs
      .collection('StudentLessonStatistics')
      .doc(id)
      .update({
        'metadata.lastUpdatedAt': new Date().toISOString(),
        completedQuizzes: completedQuizzes
      });
  }

  getSceneByStudentId(studentId: string): Observable<any> {
    return this.afs
      .collection(collection.scenes, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('students', 'array-contains', studentId);
        return query;
      })
      .valueChanges();
  }

  getSceneById(id: string, userId?: string | null): Observable<any> {
    return this.afs
      .collection(collection.scenes, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('id', '==', id);
        if (userId) {
          query = query.where('students', 'array-contains', userId);
        }
        return query;
      })
      .valueChanges();
  }

  getSubjectById(id: string): Observable<any> {
    return this.afs
      .collection(collection.subjects, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('id', '==', id);
        /*   if (userId) {
            query = query.where('students', 'array-contains', userId);
          } */
        return query;
      })
      .valueChanges();
  }

  //LA: LiveAssignment
  //LAP : LiveAssignmentParticipant
  //QP : QuizPackage
  //Q: Quiz

  getLaByCode(code: string): Observable<any> {
    return this.afs
      .collection(collection.liveassignments, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('joinCode', '==', code);
        return query;
      })
      .valueChanges();
  }

  getLapByUidAndLaId(userId: string, liveAssignmentId: string): Observable<any> {
    return this.afs
      .collection(collection.liveassignmentparticipants, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('userId', '==', userId);
        query = query.where('liveAssignmentId', '==', liveAssignmentId);
        return query;
      })
      .valueChanges();
  }

  getLapByLaId(liveAssignmentId: string): Observable<any> {
    return this.afs
      .collection(collection.liveassignmentparticipants, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('liveAssignmentId', '==', liveAssignmentId);
        return query;
      })
      .valueChanges();
  }

  getQpByQuizPackageId(id: string): Observable<any> {
    return this.afs
      .collection(collection.quizpackages, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('id', '==', id);
        return query;
      })
      .valueChanges();
  }

  getQuizById(id: string): Observable<any> {
    return this.afs
      .collection(collection.quizzes, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('id', '==', id);
        return query;
      })
      .valueChanges();
  }

  getQuizResponseByQuizId(qId: string, userId?: string): Observable<any> {
    return this.afs
      .collection(collection.quizresponses, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('quizId', '==', qId);
        if (userId) {
          query = query.where('metadata.createdBy.id', '==', userId);
        }
        query = query.orderBy('metadata.createdAt', 'desc');
        return query;
      })
      .valueChanges();
  }


  getQuizResponseByQuizIdAndSessionid(qId: string, sessionId?: string, userId?: string): Observable<any> {
    return this.afs
      .collection(collection.quizresponses, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('quizId', '==', qId);
        if (sessionId) {
          query = query.where('sessionId', '==', sessionId);
        }
        if (userId) {
          query = query.where('metadata.createdBy.id', '==', userId);
        }
        //  query = query.orderBy('metadata.createdAt', 'desc');
        return query;
      })
      .valueChanges();
  }

  getQuizResponsesByQuizAndParticipantId(quizId: string, participantId?: string): Observable<any> {
    return this.afs
      .collection(collection.quizresponses, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('quizId', '==', quizId);
        if (participantId) {
          query = query.where('metadata.createdBy.id', '==', participantId);
        }
        return query;
      })
      .valueChanges();
  }

  getQuizResponsesbyLaId(liveAssignmentId: string, participantId?: string): Observable<any> {
    return this.afs
      .collection(collection.quizresponses, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('liveAssignmentId', '==', liveAssignmentId);
        if (participantId) {
          query = query.where('metadata.createdBy.id', '==', participantId);
        }
        query = query.orderBy('metadata.createdAt', 'desc');
        return query;
      })
      .valueChanges();
  }

  async addOrUpdateParticipant(participant: any, addOrUpdate: 'add' | 'update') {
    /*  if (participant.id) {
       delete participant.metadata.createdAt;
     } */
    if (!participant.id) {
      const id = this.afs.createId();
      participant.id = id;
    }
    if (addOrUpdate === 'add') {
      await this.afs
        .collection(collection.liveassignmentparticipants, ref => ref)
        .doc(participant.id)
        .set(participant, { merge: true });
      return participant.id;
    } else {
      await this.afs
        .collection(collection.liveassignmentparticipants, ref => ref)
        .doc(participant.id)
        .update({
          username: participant.username,
          'metadata.lastUpdatedAt': new Date().toISOString(),
        });
      return participant.id;
    }
  }

  createId() {
    const id = this.afs.createId();
    return id;
  }

  getUserByEmailAndRole(email: string, role: 'STUDENT' | 'TEACHER') {
    return this.afs
      .collection(collection.users, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('email', '==', email);
        query = query.where('role', '==', role);
        return query;
      })
      .valueChanges();
  }

  getUserByConfirmTokenAndRole(token: string, role: 'STUDENT' | 'TEACHER') {
    return this.afs
      .collection(collection.users, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('emailConfirmationData.token', '==', token);
        query = query.where('role', '==', role);
        return query;
      })
      .valueChanges();
  }

  getParticipantByUIdAndLaId(userId: string, liveAssignmentId?: string) {
    return this.afs
      .collection(collection.liveassignmentparticipants, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('userId', '==', userId);
        if (liveAssignmentId) {
          query = query.where('liveAssignmentId', '==', liveAssignmentId);
        }
        query = query.orderBy('metadata.createdAt', 'desc');
        return query;
      })
      .valueChanges();
  }

  addOrUpdateDocument(collectionName: string, data: any) {
    if (data.id) {
      return this.update(collectionName, data.id, data);
    } else {
      if (!data.id) {
        const id = this.afs.createId();
        data.id = id;
      }

      return this.afs
        .collection(collectionName, ref => ref)
        .doc(data.id)
        .set(data);
    }
  }

  async addOrUpdateDocumentWithReturnId(collectionName: string, data: any) {
    if (data.id) {
      await this.saveOrUpdateData(collectionName, data);
      return data.id;
    } else {
      if (!data.id) {
        const id = this.afs.createId();
        data.id = id;
      }
      await this.afs
        .collection(collectionName, ref => ref)
        .doc(data.id)
        .set(data);
    }
    return data.id;
  }

  getFormResponses(profileId: string | null | undefined, status: string): Observable<any[]> {
    let todayDate = new Date().toISOString();
    if (profileId) {
      const query: QueryParam[] = [
        {
          field: 'author.reference',
          opStr: '==',
          value: profileId ? profileId : null,
        },
        {
          field: 'status',
          opStr: '==',
          value: status,
        },
        {
          field: 'start',
          opStr: '<=',
          value: todayDate,
        },
        /*    {
        field: 'end',
        opStr: '<=',
        value: todayDate,
      }, */
      ];

      const orderBy = { active: 'start', direction: 'desc' };
      return this.getByMultiField('FormResponses', 24, undefined, query, orderBy).pipe(
        map((list: any) => list.map((item: any) => (item.data ? item.data : item))),
        catchError(error => {
          console.log(error);
          console.error('error loading the list', error);
          return of();
        })
      );
    } else {
      return of();
    }
  }

  getById(collectionName: string, id: string) {
    return this.afs.collection(collectionName).doc(id).valueChanges();
  }

  getByName(collectionName: string, name: string, limit: number = 1, fieldPath: string = 'name', opStr = 'array-contains', org?: string, parentPath = 'parentId') {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (org) {
          query = query.where(parentPath, '==', org);
        }
        if (name) {
          query = query.where(fieldPath, opStr as any, name);
        }
        query = query.limit(limit);
        return query;
      })
      .valueChanges();
  }

  searchByValue(collection: string, field: string, value: string, limit: number, org?: string) {
    return this.afs
      .collection(collection, ref => {
        let query: CollectionReference | Query = ref;
        if (org) {
          query = query.where('parentId', '==', org);
        }
        query = query.orderBy(field);
        query = query.startAt(value);
        query = query.limit(limit);
        return query;
      })
      .valueChanges();
  }

  getByMultiField(collectionName: string, limit = 24, startAt?: any, queryParam?: QueryParam[], orderBy?: any) {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (queryParam) {
          queryParam.forEach(e => {
            if (e.field && e.opStr && e.value) {
              query = query.where(e.field, e.opStr as any, e.value);
            }
          });
        }
        if (limit) {
          query = query.limit(limit);
        }
        if (orderBy?.active && orderBy?.direction) {
          query = query.orderBy(orderBy.active, orderBy.direction);
        } else {
          query = query.orderBy('id');
        }
        if (startAt) {
          query = query.startAfter(startAt);
        }
        return query;
      })
      .valueChanges();
  }

  /** @deprecated Use 'saveOrUpdateData(collection: string, data: any)' instead */
  async add(collectionName: string, data: any, id?: string) {
    const uid = id ? id : this.afs.createId();
    data.id = uid;
    await this.afs.collection(collectionName).doc(uid).set(data);
    return uid;
  }

  saveOrUpdateData(collection: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (collection && data) {
        if (!data.id) {
          data.id = this.afs.createId();
        }
        if (!data.metadata) {
          data.metadata = {};
        }
        if (!data.metadata.createdBy) {
          data.metadata.createdBy = {};
        }
        if (!data.metadata.createdBy.id) {
          data.metadata.createdBy.id = this.logger.getUserId();
        }
        if (!data.metadata.createdAt) {
          data.metadata.createdAt = new Date().toISOString();
        }
        if (!data.metadata.lastUpdatedBy) {
          data.metadata.lastUpdatedBy = {};
        }
        // Always update lastUpdated at and by
        data.metadata.lastUpdatedAt = new Date().toISOString();
        data.metadata.lastUpdatedBy.id = this.logger.getUserId();
        this.afs
          .collection(collection)
          .doc(data.id)
          .set(data)
          .then(() => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  /** @deprecated Use 'saveOrUpdateData(collection: string, data: any)' instead */
  set(collectionName: string, id: string, data: any) {
    return this.afs.collection(collectionName).doc(id).set(data);
  }

  /** @deprecated Use 'saveOrUpdateData(collection: string, data: any)' instead */
  update(collectionName: string, id: string, data: any) {
    return this.afs.collection(collectionName).doc(id).update(data);
  }
  updateQuestions(collectionName: string, id: string, questions: any, status?: string, filledDate?: boolean) {
    return this.afs
      .collection(collectionName)
      .doc(id)
      .update({
        lastUpdated: new Date().toISOString(),
        questions: questions,
        status: status,
        filledDate: filledDate ? new Date().toISOString() : null,
      });
  }

  delete(collectionName: string, id: string) {
    return this.afs.collection(collectionName).doc(id).delete();
  }
}
